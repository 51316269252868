import React, { useEffect } from 'react'
import Addicon from '../Assetes/Add.svg'
import backIcon from '../Assetes/icon.svg'
import './ProfileSliderCategory.css'
import { FormControl, Input, InputLabel } from '@mui/material'
import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { arrayEmerge } from '../Services/state'
// import { generateUID } from "../utils/uuid";
function ProfileSliderCategory(props) {
  const [EventFormat, setEventFormat] = useState([])
  const [datatag, setdatatag] = useState('')
  const [Eventtype, setEventtype] = useState([])
  const [EventTypeData, setEventTypeData] = useState('')
  const [Typeof, setTypeof] = useState([])
  const [TypeofData, setTypeofData] = useState('')
  const [value, setvalue] = useRecoilState(arrayEmerge)

  useEffect(() => {
    const newArray = [...EventFormat, ...Eventtype, ...Typeof]
    setvalue(newArray)
  }, [EventFormat, Eventtype, Typeof])
  const OnpressAddData = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      setEventFormat((data1) => [...data1, datatag])
      document.getElementById('standard-adornment-weight').value = ''
    }
  }
  const OnpressAddDataToType = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      setEventtype((data1) => [...data1, EventTypeData])
      document.getElementById('DataToType').value = ''
    }
  }
  const OnpressOpento = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      setTypeof((data1) => [...data1, TypeofData])
      document.getElementById('Type_of').value = ''
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        width: '90%',
        backgroundColor: 'white',
        position: 'absolute',
        height: '100vh',
        flexDirection: 'column',
        alignSelf: 'flex-end',
        left: 0,
        zIndex: 1,
      }}
      className='border'
    >
      <div className='silder_start1'>
        <img src={backIcon} onClick={props.handleEvent} />
        <p className='Header_title'>Play Style</p>
      </div>
      <div>
        <text className='Event_header'>Event Format</text>
        <FormControl
          style={{
            width: '98%',
            paddingLeft: 10,
            paddingRight: 12,
            backgroundColor: '#F0F7FF',
          }}
          variant='standard'
          sx={{ m: 1, mt: 3, width: '25ch' }}
        >
          <InputLabel
            style={{ paddingLeft: 12, color: '#03294FCC' }}
            htmlFor='my-input'
          >
            Add Your own
          </InputLabel>
          <Input
            style={{ height: 45, color: '#03294FCC', paddingLeft: 10 }}
            onKeyDown={OnpressAddData}
            id='standard-adornment-weight'
            endAdornment={<img position='end' alt='' src={Addicon} />}
            aria-describedby='standard-weight-helper-text'
            inputProps={{
              'aria-label': 'weight',
            }}
            onChange={(e) => {
              setdatatag(e.target.value)
            }}
          />
        </FormControl>
        <div
          className='my-scrollable-div'
          style={{
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'scroll',
            marginLeft: 10,
          }}
        >
          {EventFormat.map((item) => {
            return <p className='ProfileSlider_tags'>{item}</p>
          })}
        </div>
        <text className='Event_header'>Event Type</text>
        <FormControl
          style={{
            width: '88%',
            paddingLeft: 10,
            paddingRight: 12,
            backgroundColor: '#F0F7FF',
          }}
          variant='standard'
          sx={{ m: 1, mt: 3, width: '25ch' }}
        >
          <InputLabel
            style={{ paddingLeft: 12, color: '#03294FCC' }}
            htmlFor='my-input'
          >
            Add Your own
          </InputLabel>
          <Input
            style={{ height: 45, color: '#03294FCC', paddingLeft: 10 }}
            onKeyDown={OnpressAddDataToType}
            id='DataToType'
            endAdornment={<img position='end' alt='' src={Addicon} />}
            aria-describedby='standard-weight-helper-text'
            inputProps={{
              'aria-label': 'weight',
            }}
            onChange={(e) => {
              setEventTypeData(e.target.value)
            }}
          />
        </FormControl>
        <div
          className='my-scrollable-div'
          style={{
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'scroll',
            paddingLeft: 10,
          }}
        >
          {Eventtype.map((item) => {
            return <p className='ProfileSlider_tags'>{item}</p>
          })}
        </div>
        <text className='Event_header'>Open to</text>
        <FormControl
          style={{
            width: '88%',
            paddingLeft: 10,
            paddingRight: 12,
            backgroundColor: '#F0F7FF',
          }}
          variant='standard'
          sx={{ m: 1, mt: 3, width: '25ch' }}
          className='my-scrollbar'
        >
          <InputLabel
            style={{ paddingLeft: 12, color: '#03294FCC' }}
            htmlFor='my-input'
          >
            Add Your own
          </InputLabel>
          <Input
            style={{ height: 45, color: '#03294FCC', paddingLeft: 10 }}
            onKeyDown={OnpressOpento}
            id='Type_of'
            endAdornment={<img position='end' alt='' src={Addicon} />}
            aria-describedby='standard-weight-helper-text'
            inputProps={{
              'aria-label': 'weight',
            }}
            onChange={(e) => {
              setTypeofData(e.target.value)
            }}
          />
        </FormControl>
        <div
          className='my-scrollable-div'
          style={{
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'scroll',
            marginLeft: 10,
          }}
        >
          {Typeof.map((item) => {
            return <p className='ProfileSlider_tags'>{item}</p>
          })}
        </div>
      </div>
    </div>
  )
}
export default ProfileSliderCategory
