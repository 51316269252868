import React, { useEffect } from "react";
import "./Login.css";
import mainImage from "../../Assetes/Logo.png";
import { Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import config from "../../Services/config";
import { useRecoilState, useRecoilValue } from "recoil";
import { Token, user_id } from "../../Services/state";
function Login() {
  const navigate = useNavigate();
  const [passcode, setPasscode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [profileData, setProfileData] = useState([]);
  const [isIncorrectPasscode, setIsIncorrectPasscode] = useState(false);
  const handlePasscodeChange = (event) => {
    setPasscode(event.target.value);
  };

  const onSubmit = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      try {
        const response = await fetch(config.backend_url + "/admin/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ passcode: passcode }),
        });
        const { data } = await response.json();
        if (!data?.token) {
          setIsIncorrectPasscode(true);
        } else {
          setIsIncorrectPasscode(false);

          localStorage.setItem("token", data?.token);
          localStorage.setItem("eventUserId",data?.userId)
          console.log(data,"data is here ")

          event.preventDefault();
          navigate("/Admin");
        }
      } catch (error) {
        console.error(error);
        setErrorMessage("Something went wrong");
      }
    }
  };
 

  return (
    <div className="main">
      <div style={{ paddingLeft: 10 }}>
        <img src={mainImage} style={{ height: 132, width: 270 }} />
      </div>
      <input
        className="input"
        required=""
        type="password"
        alt="Passcode"
        onKeyDown={onSubmit}
        placeholder="Enter the 10 digit passcode"
        onChange={handlePasscodeChange}

      />
      {isIncorrectPasscode && <div>Incorrect passcode, please try again</div>}
      {errorMessage && <div>{errorMessage}</div>}
    </div>
  );
}

export default Login;

// const slider = () => {
//     <div>
//       <button style={{ zIndex: 1 }} onClick={handleToggleSlider}>Toggle Slider</button>
//       <div className="App">
//         {sliderVisible && (
//           <div className='border'>
//             <div className='silder_start'></div>
//             <h2 style={{ marginRight: 260, fontSize: 16 }}>Course Info</h2>
//             <div style={{ display: 'flex', flexDirection: "column", gap: 12, paddingLeft: 25, width: 350 }}>
//               <TextField sx={{ marginBottom: 2, width: "100%" }} className='input_Field' label="Course" variant="outlined" />
//               <TextField sx={{ width: "100%" }} label="Street" variant="outlined" />
//               <TextField sx={{ width: "100%" }} label="Town/City" variant="outlined" />
//               <div style={{ display: 'flex', flexDirection: 'row' }}>
//                 <TextField sx={{ width: "42%" }} label="State" variant="outlined" />
//                 <TextField sx={{ marginLeft: 1, width: "50%" }} label="ZipCode" variant="outlined" />
//               </div>
//               <h2 style={{ marginRight: 260, fontSize: 16, width: 100 }}>Personal Info</h2>
//               <TextField sx={{ marginBottom: 2, width: "100%" }} className='First Name' label="Course" variant="outlined" />
//               <TextField sx={{ width: "100%" }} label="Last Name" variant="outlined" />
//               <TextField sx={{ width: "100%" }} label="Role" variant="outlined" />
//               <h2 style={{ marginRight: 260, fontSize: 16, width: 100 }}>Contact Info</h2>
//               <TextField sx={{ marginBottom: 2, width: "100%" }} className='Email' label="Course" variant="outlined" />
//               <TextField sx={{ width: "100%" }} label="Phone" variant="outlined" />
//               <TextField sx={{ width: "100%" }} label="Town/City" variant="outlined" />
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   }
