import {
  FormControl,
  FormControlLabel,
  Hidden,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../../Assetes/linked-golf-logo.svg";
import Add from "../../Assetes/plus-button.svg";
import "./Admin.css";
import Toggle from "react-styled-toggle";
import backIcon from "../../Assetes/icon.svg";
import ProfileSlider from "../Silder/ProfileSlider";
import EventSlider from "../Silder/EventSlider";
import config, { backend_url } from "../../Services/config";
import { user_id, Token } from "../../Services/state";
import { id_for_event } from "../../Services/state";
import { useRecoilState, useRecoilValue } from "recoil";
import { Navigate, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { FaSignOutAlt } from "react-icons/fa";
import Addicon from "../../Assetes/Add.svg";
import Playstyle from "../../Assetes/Playstyle.svg";
import axios from "axios";

function Admin() {
  const [sliderVisible, setSliderVisible] = useState(false);
  const [responseLocationData, setResponseLocationData] = useState([]);
  const [event, setEvent] = useState(false);
  const [Existedevent, setExistedEvent] = useState(false);
  const [coursename, setCourseName] = useState("");
  const [address, setAddress] = useState("");
  const [firstname, setFirstName] = useState("");
  const [Lastname, setLastName] = useState("");
  const [Role, setRole] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [Town, setTown] = useState("");
  const [street, setStreet] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [id, setId] = useRecoilState(id_for_event);
  const [value, setvalue] = useRecoilState(user_id);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [onPressAdd, setOnPressAdd] = useState([]);
  const [verify, setVerify] = useState([]);
  const [getAllUsers, setGetAllUsers] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userID, setUserID] = useState(null);
  const [state, setState] = useState("");
  const token = localStorage.getItem("token");
  const [locationData, setLocationData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [location, setLocation] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [placeId, setPlaceId] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [eventUserMail,setEventUserMail] = useState("");
  const [contactMethod, setContactMethod] = useState("phone");

  const allUsers = () => {
    var myHeaders = new Headers();
    var token = localStorage.getItem("token");
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${backend_url}/getAllUsers`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setGetAllUsers(result?.data);
        // console.log(result?.data, "setGetAllUsers");
      })
      .catch((error) => console.log("error", error));
  };

  const handleEvent = (item) => {
   
    setEventUserMail(item?.email);
    console.log(eventUserMail, "eventUserMail");
    setId(item?._id);
    setEvent(!event);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  useEffect(() => {
    if (!event) {
      setSelectedEvent("");
    }
  }, [event]);

  const handleEvent1 = (item, eventId) => {
    console.log("unmount");
    console.log({ item });
    setSelectedEvent(item);
    setEvent(!event);
    // setSelectedEventId(eventId);
    // if (Existedevent === false) {
    //   setSelectedEvent('');
    // }
    // setExistedEvent(!Existedevent);
  };
  const handleToggleSlider = () => {
    setSliderVisible(!sliderVisible);
  };

  const locationEvents = () => {
    var myHeaders = new Headers();
    var token = localStorage.getItem("token");
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (!navigator.geolocation) {
      alert(
        "Geolocation is not supported by your browser. Please enable it to proceed."
      );
      return;
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        // Save latitude and longitude to localStorage
        localStorage.setItem("lat", latitude);
        localStorage.setItem("lng", longitude);

        fetch(
          `https://api.linkedgolfapp.com/getAllGolfCourses/${latitude}/${longitude}/50000`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            setResponseLocationData(result?.data);
            // console.log(result, "location api");
            // console.log(result?.data?.place_id,"result?.data?.place_id");
            // setPlaceId(result?.data?.place_id)
          });
      },
      (error) => {
        alert(
          "Failed to retrieve your location. Please enable location services to proceed."
        );
        console.log(error);
      }
    );
  };

  const handleLocationChange = (event) => {
    const selectedLocationName = event.target.value;

    // Find the selected location object using the name
    const selectedLocationObject = locationData.find(
      (location) => location.name === selectedLocationName
    );

    if (selectedLocationObject) {
      const selectedPlaceId = selectedLocationObject.place_id;
      setSelectedLocation(selectedPlaceId); // Set the place_id in a state variable
    }
  };

  const OnpressAddData = (e) => {
    console.log("UserId", userID);

    if (coursename === "" || coursename === null) {
      alert("coursename field is  missing");
      return;
    } else if (address === "" || address === null) {
      alert("address field is  missing");
      return;
    } else if (firstname === "" || firstname === null) {
      alert("firstname field is  missing");
      return;
    } else if (Lastname === "" || Lastname === null) {
      alert("Lastname field is  missing");
      return;
    } else if (Role === "" || Role === null) {
      alert("Role field is  missing");
      return;
    } else if (Email === "" || Email === null) {
      alert("Email field is  missing");
      return;
    } else if (Phone === "" || Phone === null || Phone === undefined) {
      alert("Phone field is  missing");
      return;
    } else if (state === "" || state === null) {
      alert("street field is  missing");
      return;
    } else if (zipcode === "" || zipcode === null) {
      alert("zipcode field is  missing");
      return;
    } else if (street === "" || street === null) {
      alert("street field is  missing");
      return;
    } else if (zipcode === "" || zipcode === null) {
      alert("zipcode field is  missing");
      return;
    } else if (contactMethod === "" || contactMethod === null) {
      alert("contactMethod field is  missing");
      return;
    } else if (placeId === "" || placeId === null || placeId === undefined) {
      alert("please Select Location");
      return;
    }

    e.preventDefault();
    var myHeaders = new Headers();
    var token = localStorage.getItem("token");

    myHeaders.append("Authorization", "Bearer " + token);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        course: coursename,
        courseAddress: street,
        city: address,
        zipcode: zipcode,
        firstName: firstname,
        lastName: Lastname,
        email: Email,
        number: Phone,
        role: Role,
        state: state,
        courseId: placeId,
        contactMethod,
      }),
    };
    console.log(userID, "userID");
    fetch(`${config.backend_url}/claimCourse/${userID}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        data.push(result);
        setData([...data]);
        getEventList();
      })
      .catch((error) => console.error(error));
    setCourseName("");
    setStreet("");
    setAddress("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setZipCode("");
    // setTown("");
    handleToggleSlider();
    setState("");
    setRole("");
    setContactMethod("");
  };

  useEffect(() => {
    getEventList();
    allUsers();
    locationEvents();
    // console.log(localStorage.getItem("token"), "token");
  }, []);

  const getEventList = () => {
    fetch(config.backend_url + "/allClaimCourse", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAllData([...data]);
        setLoading(false);
      })
      .catch((error) => {
        // Handle any errors that occur
        console.error(error);
      });
  };
  const claimedCoursesVerified = (claimCourse_id) => {
    fetch(config.backend_url + "/claim/" + claimCourse_id + "/verify", {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setVerify(data);
        getEventList();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleToggle = (claimCourse_id) => {
    claimedCoursesVerified(claimCourse_id);
  };

  const handleDelete = (id) => {
    fetch(config.backend_url + "/deleteClaimCourse/" + id, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        alert(data?.message);
        getEventList();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleOptionSelect = (name, placeId) => {
    setSelectedOption(name); // Set the selected option name
    setPlaceId(placeId);
    setIsDropdownOpen(false);
  };
  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const override = css`
    display: block;
    margin: 0 auto;
  `;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {sliderVisible && (
        <Modal
          open={sliderVisible}
          onClose={!sliderVisible}
          style={{
            overflow: "auto",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <div
            style={{
              display: "flex",
              backgroundColor: "white",
              width: "30%",
              flexDirection: "column",
            }}
          >
            <div className="silder_start">
              <img src={backIcon} onClick={handleToggleSlider} />
            </div>
            <div
              style={{
                paddingLeft: 15,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
              }}
            >
              <h2 className="headingSlider">Course Info</h2>
              <TextField
                sx={{ marginBottom: 4, width: "97%" }}
                label="Course"
                variant="outlined"
                id="Course_name"
                onChange={(e) => {
                  setCourseName(e.target.value);
                }}
              />

              <TextField
                sx={{ width: "97%", marginBottom: 2 }}
                label="Street"
                variant="outlined"
                id="Street"
                onChange={(e) => {
                  setStreet(e.target.value);
                }}
              />
              <TextField
                sx={{ width: "97%", marginBottom: 2 }}
                label="Town/City"
                variant="outlined"
                id="City"
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: 32,
                }}
              >
                <TextField
                  sx={{ width: "42%" }}
                  label="State"
                  variant="outlined"
                  id="State"
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                />
                <TextField
                  sx={{ marginLeft: 1, width: "53%" }}
                  label="ZipCode"
                  variant="outlined"
                  id="Zipcode"
                  type="number"
                  onChange={(e) => {
                    setZipCode(e.target.value);
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    paddingLeft: 16,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    position: "relative",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img src={Playstyle} alt="Playstyle" />
                    <p style={{ paddingLeft: 15 }}>Location</p>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      marginLeft: "80%",
                      marginTop: "5%",
                    }}
                  >
                    <img
                      style={{}}
                      src={Addicon}
                      alt="Addicon"
                      onClick={handleDropdownClick}
                    />
                    {/* Dropdown */}
                    {isDropdownOpen && (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          backgroundColor: "white",
                          padding: "10px",
                          width: "200px",
                          height: "150px",
                          overflowY: "auto",
                          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                          zIndex: 1,
                          marginLeft: "-1680%",
                        }}
                      >
                        {/* Dropdown options */}
                        {responseLocationData?.map((data) => (
                          <p
                            key={data.id}
                            onClick={() =>
                              handleOptionSelect(data.name, data.place_id)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {data.name}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                {selectedOption && (
                  <p style={{ marginLeft: "5%", marginTop: "10px" }}>
                    Selected Location: {selectedOption}
                  </p>
                )}
                {location && <p>Chosen Location: {location}</p>}
              </div>

              <h2 className="headingSlider">Personal Info</h2>

              <div
                style={{ width: "80%", marginBottom: "8%", marginTop: "8%" }}
              >
                <label style={{ fontWeight: "500", height: "25%" }}>
                  User Name
                  <select
                    style={{ width: "60%", height: "130%", marginLeft: "10%" }}
                    onChange={(e) => {
                      const selectedUser = e.target.value;
                      // Find the selected user's details from the available users
                      const selectedUserDetails = getAllUsers.find(
                        (user) =>
                          user.firstName + " " + user.lastName === selectedUser
                      );
                      console.log(selectedUserDetails._id);
                      // Set the first name and last name in the text fields
                      setFirstName(selectedUserDetails?.firstName);
                      setLastName(selectedUserDetails?.lastName);
                      setEmail(selectedUserDetails?.email);
                      setPhone(selectedUserDetails?.number);
                      setTown(selectedUserDetails?.location);
                      setUserID(selectedUserDetails?._id);
                    }}
                  >
                    {getAllUsers.map((item) => (
                      <option value={`${item.firstName} ${item.lastName}`}>
                        {item?.firstName} {item?.lastName}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <TextField
                sx={{ marginTop: 2, paddingBottom: 2, width: "97%" }}
                label="First Name"
                variant="outlined"
                id="firstName"
                value={firstname}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
              <TextField
                sx={{ width: "97%", paddingBottom: 2 }}
                label="Last Name"
                variant="outlined"
                id="LastName"
                value={Lastname}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />

              <TextField
                sx={{ width: "97%", paddingBottom: 5 }}
                label="Role"
                variant="outlined"
                id="Role"
                onChange={(e) => {
                  setRole(e.target.value);
                }}
              />
              <h2 className="headingSlider">Contact Info</h2>
              <TextField
                sx={{ marginBottom: 2, width: "97%" }}
                className="Email"
                label="Email"
                variant="outlined"
                id="Email"
                value={Email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <TextField
                sx={{ width: "97%", marginBottom: 1 }}
                label="Phone"
                variant="outlined"
                id="Phone"
                value={Phone}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters

                  // Check if the input is a numeric value and does not exceed 12 digits
                  if (!isNaN(numericValue) && numericValue.length <= 12) {
                    setPhone(numericValue);
                  }
                }}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  maxLength: 12,
                }}
              />
              {/* Contact Method Field */}
              {/* Threeb methods phone text email */}

              <InputLabel id="contact-method-label" sx={{ color: "black" }}>
                Contact Method
              </InputLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                onChange={(e) => {
                  setContactMethod(e.target.value);
                }}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Phone"
                  control={<Radio />}
                  label="Phone"
                />
                <FormControlLabel
                  value="Email"
                  control={<Radio />}
                  label="Email"
                />
                <FormControlLabel
                  value="Text"
                  control={<Radio />}
                  label="Text"
                />
              </RadioGroup>

              {/* <TextField
                sx={{ width: "97%", paddingBottom: 5 }}
                label="Town/City"
                variant="outlined"
                id="Town"
                value={Town}
                onChange={(e) => {
                  setTown(e.target.value);
                }}
              /> */}
            </div>
            <div
              style={{
                width: "100%",
                alignSelf: "center",
                textAlignLast: "center",
                paddingTop: 7,
                backgroundColor: "white",
              }}
            >
              <button
                className="Create_event_button"
                onClick={(e) => {
                  OnpressAddData(e);
                }}
                style={{
                  marginBottom: "20px",
                }}
              >
                Claim Course
              </button>
            </div>
          </div>
        </Modal>
      )}
      {event && (
        <ProfileSlider handleEvent={handleEvent} usermail={eventUserMail} event={selectedEvent} />
      )}
      {Existedevent && <EventSlider handleEvent1={handleEvent1} />}
      <div
        style={{
          width: "100%",
          height: 70,
          backgroundColor: "#03294F",
          display: "flex",
        }}
      >
        <img
          alt=""
          src={logo}
          style={{ width: "10%", height: 70, paddingLeft: "10%" }}
        />
        <FaSignOutAlt
          onClick={handleLogout}
          style={{
            marginLeft: "64%",
            width: "7%",
            height: "40%",
            color: "white",
            marginTop: "1.8%",
            cursor: "pointer",
            transition: "color 0.3s ease-out",
          }}
        />
      </div>
      <div>
        <div
          style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "5%" }}
        >
          <div
            style={{
              justifyContent: "space-between",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <h2>Admin</h2>
            <img
              alt=""
              onClick={handleToggleSlider}
              src={Add}
              style={{ height: 70 }}
            />
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <div
              style={{
                width: "50%",
                flexDirection: "row",
                display: "flex",
                padding: 15,
              }}
            >
              <text style={{ width: "40%" }}>NAME</text>
              <text style={{ width: "25%" }}>CLAIM DATE</text>
              <text style={{ width: "30%", marginLeft: "24px" }}>
                GOLF COURSE
              </text>
            </div>
            <div
              style={{
                width: "50%",
                flexDirection: "row",
                display: "flex",
                padding: 15,
              }}
            >
              <text style={{ width: "26%", marginLeft: "-2%" }}>ROLE</text>
              <text style={{ width: "26%", marginLeft: "5%" }}>PHONE</text>
              <text style={{ width: "26%", marginLeft: "-1.3%" }}>EVENTS</text>
              <text style={{ width: "2%", marginLeft: "2.9%" }}>VERIFIED</text>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "grey",
              height: 1,
            }}
          />
          <div style={{ height: "100%", overflow: "auto" }}>
            {loading ? (
              <div
                style={{
                  marginLeft: "50%",
                  marginTop: "10%",
                }}
              >
                <ClipLoader
                  color={"#123abc"}
                  loading={loading}
                  css={override}
                  size={30}
                />
              </div>
            ) : (
              allData
                ?.sort(
                  (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
                )
                ?.map((item) => (
                  <div>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        paddingTop: 5,
                        paddingBottom: 5,
                        opacity: 1,
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          flexDirection: "row",
                          display: "flex",
                          padding: 15,
                        }}
                      >
                        <div
                          style={{
                            width: "40%",
                            flexDirection: "column",
                            display: "flex",
                          }}
                        >
                          <text>{item?.firstName + " " + item?.lastName}</text>
                        </div>
                        <text style={{ width: "25%", marginLeft: "1%" }}>
                          {item?.createdAt &&
                            new Date(item.createdAt).toLocaleDateString(
                              "en-US"
                            )}
                        </text>

                        <div
                          style={{
                            width: "35%",
                            flexDirection: "column",
                            display: "flex",
                            marginLeft: "-4%",
                          }}
                        >
                          <text style={{ marginLeft: "50px" }}>
                            {item.course}
                          </text>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          flexDirection: "row",
                          display: "flex",
                          padding: 15,
                        }}
                      >
                        <text
                          className="roles"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "20%",
                          }}
                        >
                          {item?.role &&
                            item.role.split(" ").slice(0, 2).join(" ")}
                          {item?.role &&
                            item.role.split(" ").length > 2 &&
                            " ..."}
                        </text>

                        <text style={{ width: "26%", paddingLeft: 40 }}>
                          {item.number}
                        </text>
                        <Select
                          style={{
                            width: "20%",
                            marginRight: 10,

                            marginTop: -10,
                            color: "#0B7DE6",
                          }}
                        >
                          <MenuItem
                            style={{ color: "#0B7DE6" }}
                            onClick={() => handleEvent(item)}
                            value={10}
                          >
                            Create +
                          </MenuItem>
                          {/* {console.log(item)} */}
                          {item?.events?.map((value) => {
                            return (
                              <MenuItem
                                style={{ height: 50, color: "red" }}
                                onClick={() => handleEvent1(value)}
                                value={20}
                              >
                                {value?.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <div style={{ paddingLeft: 35 }}>
                          <Toggle
                            // selected={item?.verified}
                            checked={item?.verified === true ? true : false}
                            // checked={!!item?.verified}
                            onChange={() => {
                              handleToggle(item?._id);
                            }}
                          />
                        </div>
                      </div>
                      <div
                        onClick={() => handleDelete(item?._id)}
                        style={{
                          display: "flex",
                          paddingTop: 20,
                          cursor: "pointer",
                          opacity: 1,
                          color: "red",
                        }}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Admin;
