import './App.css';
import Login from './Screens/Login/Login'
import { useState } from 'react';
import Admin from './Screens/Admin/Admin';
import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import ProfileSlider from './Screens/Silder/ProfileSlider';
import ProfileSliderCategory from './Component/ProfilleSliderCategory';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Admin" element={<Admin />} />
        <Route path="/prfileslider" element={<ProfileSlider/>}/>
        <Route path="/prfileslidercat" element={<ProfileSliderCategory/>}/>
      </Routes>
    </Router>
  );
}

export default App;

