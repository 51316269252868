import React from "react";
import profile from "../../Assetes/Profile.png";
import Calendar1 from "../../Assetes/calendar.svg";
import clock from "../../Assetes/clock.svg";
import Documentsvg from "../../Assetes/Document.svg";
import Website from "../../Assetes/Website.svg";
import Playstyle from "../../Assetes/Playstyle.svg";
import Addicon from "../../Assetes/Add.svg";
import backIcon from "../../Assetes/icon.svg";
import { useRef, useState, useEffect } from "react";
import ProfileSliderCategory from "../../Component/ProfilleSliderCategory";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./ProfileSlider.css";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import { arrayEmerge, id_for_event, user_id } from "../../Services/state";
import { useRecoilValue } from "recoil";
import axios from "axios";

import { MdTitle } from "react-icons/md";
// import { Modal } from '@mui/material'
import config from "../../Services/config";
import Modal from "react-modal";
import { Input } from "@mui/material";
function ProfileSlider(props) {
  const [imageUrl, setImageUrl] = useState(null);
  const [date, setDate] = useState(new Date());
  const [event, setEvent] = useState(false);
  const [clockDisplay, setClockDisplay] = useState(false);
  const [calenderDisplay, setCalenderDisplay] = useState(false);
  const [time, setTime] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [discriptionDisplay, setDiscriptionDisplay] = useState(false);
  const [discriptiontext, setdiscriptiontext] = useState("");
  const [urlFieldOpen, setUrlFieldOpen] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState("");
  const emergearray = useRecoilValue(arrayEmerge);
  const Id_for_Event = useRecoilValue(id_for_event);
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [discriptionText, setDiscriptionText] = useState("");
  const [titleFieldOpen, setTitleFieldOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [responseLocationData, setResponseLocationData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [userImageId, setUserImageId] = useState(null);
  const [placeId, setPlaceId] = useState("");
  const [image, setImage] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);


  useEffect(() => {
    if (props.event) {
      setTitle(props.event.title);
      setDiscriptionText(props.event.description);
      setLocation(props.event.location);
      setSelectedDate(props.event.createdAt);
      // setTime(props.event.createdAt);
      const formattedCreatedAt = props.event.createdAt
        ? moment(props.event.createdAt).format("HH:mm")
        : "";

      setTime(formattedCreatedAt);
      setWebsiteUrl(props.event.website);
      setImageUrl(props.event.image);
    }
  }, [props.event]);
  const validateDateTime = () => {
    const now = new Date();
    const selectedDateTime = new Date(selectedDate);
    const [hours, minutes] = time.split(":").map(Number);

    selectedDateTime.setHours(hours);
    selectedDateTime.setMinutes(minutes);

    return selectedDateTime > now;
  };
  const handleTitleFieldOpen = () => {
    setTitleFieldOpen(!titleFieldOpen);
  };
  const handleTitleFieldSave = () => {
    // Save the title and perform any necessary actions
    // Here, we are just updating the state with the entered title
    setTitle(title);
    setTitleFieldOpen(false);
  };

  const handleUrlFieldSave = () => {
    // Save the website URL and perform any necessary actions
    // Here, we are just updating the state with the entered URL
    setWebsiteUrl(websiteUrl);
    setUrlFieldOpen(false);
  };
  const handleDiscriptionDisplay = (e) => {
    setDiscriptionDisplay(!discriptionDisplay);
    setDiscriptionText(e.target.value);
  };

  const handleDiscriptionSave = () => {
    // Save the description and perform any necessary actions
    // Here, we are just updating the state with the entered text
    setDiscriptionText(discriptionText);
    setDiscriptionDisplay(false);
  };

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (name, placeId) => {
    setLocation(name);
    setSelectedOption(name); // Set the selected option name
    setPlaceId(placeId);
    setIsDropdownOpen(false);
  };

  function handleImageUpload(event) {
    const file = event.target.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onload = function (evt) {
      setImageUrl(evt.target.result);
    };
    reader.readAsDataURL(file);
  }
  function handleButtonClick() {
    document.getElementById("file-input").click();
  }
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleEvent = () => {
    setEvent(!event);
  };
  const handlecalenderDisplay = () => {
    // console.log(Date.now());
    // setSelectedDate(Date.now());
    // console.log(selectedDate);
    setCalenderDisplay(!calenderDisplay);
  };
  const handleclockDisplay = () => {
    setClockDisplay(!clockDisplay);
  };
  const handleUrlFieldOpen = () => {
    setUrlFieldOpen(!urlFieldOpen);
  };
  let tags = ["Chip Label", "Tag 2", "Chip Label", "Chip Label", "Tag 5"];

  const onPressaddData = (e) => {

    e.preventDefault();
    if (!validateDateTime()) {
      // alert(`Selected Date: ${selectedDate.toISOString().split("T")[0]} Time: ${time}`);
      alert("Please select a valid date and time");
      return;
    }
    if (time === "" || time === null) {
      alert("Time field is missing");
      return;
    } else if (selectedDate === "" || selectedDate === null) {
      alert("Date field is missing");
      return;
    } else if (title === "" || title === null) {
      alert("title field is missing");
      return;
    } else if (image === "" || image === null) {
      alert("image is missing ");
      return;
    }

    var token = localStorage.getItem("token");
    var eventUserId = localStorage.getItem("eventUserId");

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        title: title,
        date: selectedDate,
        startTime: time,
        description: discriptionText,
        website: websiteUrl,
        location: location,
        userEmail: props.usermail,
        placeId,
      }),
    };

    console.log("Event User Id", eventUserId);

    fetch(`${config.backend_url}/addEventUser/${eventUserId}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(async ({ data }) => {
        console.log("Data in Then", data);
        setUserImageId(data?._id); // Update the userImageId state with the extracted _id value
        // Call the second API to update the image

        const formData = new FormData();
        formData.append("image", image);
        formData.append("placeId", placeId);
        formData.append("eventUserId", data?.eventId);
        formData.append("userId", data?.addedEventUser?.userId);
        formData.append("userEmail", props.usermail);

        const imageRequestOptions = {
          // method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-type": `multipart/form-data`,
          },
        };

        const response = await axios.post(
          `${config.backend_url}/updateImage/${data?.addedEventUser?.userId}`,
          formData,
          imageRequestOptions
        );
        return response;
      })
      .then((response) => {
        console.log(response, "response of image api");
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        return response.data;
      })
      .then((data) => {
        // Handle the response of the second API if required
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setIsOpen(false);
    props.handleEvent();
  };

  useEffect(() => {
    locationEvents();
    loginProfile();
  }, []);

  const upDateData = async (e) => {

    e.preventDefault();
    if (!validateDateTime()) {
      // alert(`Selected Date: ${selectedDate.toISOString().split("T")[0]} Time: ${time}`);
      alert("Select Future Date and Time");
   
      return;
    }
    if (time === "" || time === null) {
      alert("Time field is missing");
      return;
    } else if (selectedDate === "" || selectedDate === null) {
      alert("Date field is missing");
      return;
    } else if (title === "" || title === null) {
      alert("title field is missing");
      return;
    } else if (image === "" || image === null) {
      alert("image is missing");
      return;
    }

    var token = localStorage.getItem("token");
    var eventUserId = localStorage.getItem("eventUserId");

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        title: title,
        updatedAt: selectedDate,
        updatedAt: time,
        description: discriptionText,
        website: websiteUrl,
        location: location,
        placeId: props.event.placeId,
      }),
    };
    console.log("Request Options", requestOptions);
    console.log("Props Event", props);
    await fetch(
      `${config.backend_url}/updateEventUser/${props.event._id}`,
      requestOptions
    ).then((response) => {
      console.log({ response });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    });
    // .then(({ data }) => {
    //   setUserImageId(data?._id); // Update the userImageId state with the extracted _id value
    //   console.log({ data });

    // Call the second API to update the image
    const formData = new FormData();
    formData.append("image", image);
    formData.append("placeId", placeId);
    formData.append("eventUserId", props.event._id);
    formData.append("userId", props.event.userId);
    formData.append("userEmail", props.usermail);

    const imageRequestOptions = {
      // method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-type": `multipart/form-data`,
      },
    };
    axios.post(
      `${config.backend_url}/updateImage/${props.event._id}`,
      formData,
      imageRequestOptions
    );

    setIsOpen(false);
    props.handleEvent();
  };

  const locationEvents = () => {
    var myHeaders = new Headers();
    var token = localStorage.getItem("token");
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (!navigator.geolocation) {
      alert(
        "Geolocation is not supported by your browser. Please enable it to proceed."
      );
      return;
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        // Save latitude and longitude to localStorage
        localStorage.setItem("lat", latitude);
        localStorage.setItem("lng", longitude);

        fetch(
          `https://api.linkedgolfapp.com/getAllGolfCourses/${latitude}/${longitude}/50000`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            setResponseLocationData(result?.data);

            // console.log(result?.data?.place_id,"result?.data?.place_id");
            // setPlaceId(result?.data?.place_id)
          });
      },
      (error) => {
        alert(
          "Failed to retrieve your location. Please enable location services to proceed."
        );
        console.log(error);
      }
    );
  };
  const loginProfile = () => {
    var myHeaders = new Headers();
    var token = localStorage.getItem("token");
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://api.linkedgolfapp.com/profile", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setProfileData(result?.data);
        // console.log("Profile data:", result);
        localStorage.setItem("user_id", result?.data?._id);
      })
      .catch((error) => console.log("Error:", error));
  };

  const showSecond = true;
  const str = showSecond ? "HH:mm:ss" : "HH:mm";
  const handleTimeChange = (value) => {
    console.log("Value:", value);
    setTime(value); // Update state with selected time value
  };
  const handlecalendervalue = (value, e) => {
    console.log("Value:", value);
    setFormattedDate(value);
    setSelectedDate(value);
    handlecalenderDisplay();
  };
  useEffect(() => {
    console.log("Formatted Date:", formattedDate);
  }, [formattedDate]);
  
  useEffect(() => {
    console.log("Selected Date:", selectedDate);
  }, [selectedDate]);
  const handlediscriptionDisplay = () => {
    setDiscriptionDisplay(!discriptionDisplay);
  };
  
  console.log();
  return (
    <>
      <div>
        <Modal
          isOpen={props.handleEvent}
          onRequestClose={closeModal}
          style={{
            content: {
              display: "flex",
              height: "100%",
              width: "35%",
              padding: "10",
              backgroundColor: "white",
              position: "absolute",
              flexDirection: "column",
              alignSelf: "flex-start",
              right: "auto",
              top: "0%",
              left: "65%", // adjust this value to position the modal on the left side
              zIndex: 1,
              overflowY: "auto",
            },
          }}
        >
          {event ? (
            <ProfileSliderCategory handleEvent={handleEvent} />
          ) : (
            <div
            //  style={{
            //   display: 'flex',
            //   height: '100%',
            //   width: '35%',
            //   padding: '10',
            //   backgroundColor: 'white',
            //   position: 'absolute',
            //   flexDirection: 'column',
            //   alignSelf: 'flex-end',
            //   zIndex: 1,
            // }}
            // className="border"
            >
              <div className="silder_start">
                <img src={backIcon} onClick={props.handleEvent} />
              </div>
              <div>
                {imageUrl ? (
                  <img
                    style={{ width: "100%", height: 220 }}
                    alt=""
                    src={imageUrl}
                  />
                ) : (
                  <img
                    style={{ width: "65%", height: 183, paddingLeft: 89 }}
                    alt=""
                    src={profile}
                  />
                )}
                {imageUrl ? (
                  <>
                    <input
                      id="file-input"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                    />
                    <button className="Event-buton" onClick={handleButtonClick}>
                      Edit Picture
                    </button>
                  </>
                ) : (
                  <div className="Event-Profile">
                    <input
                      id="file-input"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                    />
                    <button className="Event-buton" onClick={handleButtonClick}>
                      Add Picture
                    </button>
                  </div>
                )}

                {calenderDisplay ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Input
                      type="date"
                      value={
                        selectedDate
                          ? selectedDate
                          : moment(new Date()).format("YYYY-MM-DD")
                      }
                      onChange={(e) => handlecalendervalue(e.target.value)}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      paddingLeft: 16,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      handlecalenderDisplay();
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img src={Calendar1} />
                      {
                        !selectedDate?(
                           <p style={{ paddingLeft: 15 }}>Select Date</p>
                         ):(
                           <p style={{ paddingLeft: 15 }}>
                             {formattedDate}
                           </p>
                        )
                      }
                      {/* <p style={{ paddingLeft: 15 }}>
                        {!selectedDate ? "Select Date" : formattedDate}
                      </p> */}
                    </div>

                    <img style={{ paddingRight: 20 }} src={Addicon} />
                  </div>
                )}
                <div
                  style={{
                    backgroundColor: "grey",
                    height: 1,
                    width: "90%",
                    marginLeft: 20,
                  }}
                ></div>
                {titleFieldOpen ? (
                  <div style={{ display: "grid" }}>
                    <textarea
                      style={{
                        marginTop: 10,
                        height: 30,
                        width: 270,
                        justifySelf: "center",
                      }}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      value={title} // Set the value of the textarea to the entered title
                    />
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <button
                        className="clock_button"
                        onClick={handleTitleFieldSave}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      paddingLeft: 16,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    onClick={handleTitleFieldOpen}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <MdTitle style={{ marginRight: 10 }} />
                      <p>{!title ? "Title" : "Title Entered"}</p>
                    </div>
                    <img
                      style={{ paddingRight: 20 }}
                      src={Addicon}
                      alt="Add Icon"
                    />
                  </div>
                )}
                {title && (
                  <div style={{ marginLeft: "5%" }}>
                    <p>Entered Title:</p>
                    <p>{title}</p>
                  </div>
                )}

                <div
                  style={{
                    backgroundColor: "grey",
                    height: 1,
                    width: "90%",
                    marginLeft: 20,
                  }}
                ></div>

                {clockDisplay ? (
                  <div style={{ flexDirection: "column", paddingLeft: 20 }}>
                    <p>Select Time</p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Input
                        type="time"
                        onChange={(e) => handleTimeChange(e.target.value)}
                      />
                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "end" }}>
                      <button
                        className="clock_button"
                        onClick={() => {
                          handleTimeChange(moment().format("HH:mm"));
                          handleclockDisplay();
                        }}
                      >
                        OK
                      </button>
                    </div> */}
                  </div>
                ) : (
                  <div
                    style={{
                      paddingLeft: 16,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      handleclockDisplay();
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img src={clock} />
                      <p style={{ paddingLeft: "10%" }}>
                        {!time ? "Start Time" : time}
                      </p>
                    </div>
                    <img style={{ paddingRight: 20 }} src={Addicon} />
                  </div>
                )}
                <div
                  style={{
                    backgroundColor: "grey",
                    height: 1,
                    width: "90%",
                    marginLeft: 20,
                  }}
                ></div>
                {/* <div
                  style={{
                    paddingLeft: 16,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onClick={handleEvent}
                >
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <img src={Playstyle} />
                    <p style={{ paddingLeft: 15 }}>Play Style (Optional)</p>
                  </div>
                  <img style={{ paddingRight: 20 }} src={Addicon} />
                </div> */}
                <div>
                  <div
                    style={{
                      paddingLeft: 16,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      position: "relative",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img src={Playstyle} alt="Playstyle" />
                      <p style={{ paddingLeft: 15 }}>Location</p>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        marginLeft: "88%",
                        marginTop: "5%",
                      }}
                    >
                      <img
                        style={{}}
                        src={Addicon}
                        alt="Addicon"
                        onClick={handleDropdownClick}
                      />
                      {/* Dropdown */}
                      {isDropdownOpen && (
                        <div
                          style={{
                            position: "absolute",
                            top: "100%",
                            backgroundColor: "white",
                            padding: "10px",
                            width: "200px",
                            height: "150px",
                            overflowY: "auto",
                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                            zIndex: 1,
                            marginLeft: "-1680%",
                          }}
                        >
                          {/* Dropdown options */}
                          {responseLocationData?.map((data) => (
                            <p
                              key={data.id}
                              onClick={() =>
                                handleOptionSelect(data.name, data.place_id)
                              }
                              value={location}
                              style={{ cursor: "pointer" }}
                            >
                              {data.name}
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {selectedOption && (
                    <p style={{ marginLeft: "5%", marginTop: "10px" }}>
                      {/* Selected Location: {selectedOption} */}
                    </p>
                  )}
                  {location && (
                    <p style={{ marginLeft: "5%" }}>
                      Chosen Location: {location}
                    </p>
                  )}
                </div>

                <div>
                  <div
                    style={{
                      backgroundColor: "grey",
                      height: 1,
                      width: "90%",
                      marginLeft: 20,
                    }}
                  ></div>
                  {discriptionDisplay ? (
                    <div style={{ display: "grid" }}>
                      <textarea
                        style={{
                          marginTop: 10,
                          height: 100,
                          width: 270,
                          justifySelf: "center",
                        }}
                        onChange={(e) => {
                          setDiscriptionText(e.target.value);
                        }}
                        value={discriptionText}
                      />
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <button
                          className="clock_button"
                          onClick={handleDiscriptionSave}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        paddingLeft: 16,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                      onClick={handleDiscriptionDisplay}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <img src={Documentsvg} alt="Documentsvg" />
                        <p style={{ paddingLeft: 15 }}>
                          {!discriptionText
                            ? "Description"
                            : "Description Entered"}
                        </p>
                      </div>
                      <img
                        style={{ paddingRight: 20 }}
                        src={Addicon}
                        alt="Addicon"
                      />
                    </div>
                  )}
                  {discriptionText && (
                    <div style={{ marginLeft: "5%" }}>
                      <p>Entered Description:</p>
                      <p>{discriptionText}</p>
                    </div>
                  )}
                </div>
                <div>
                  <div
                    style={{
                      backgroundColor: "grey",
                      height: 1,
                      width: "90%",
                      marginLeft: 20,
                    }}
                  ></div>
                  {urlFieldOpen ? (
                    <div style={{ display: "grid" }}>
                      <textarea
                        style={{
                          marginTop: 10,
                          height: 30,
                          width: 270,
                          justifySelf: "center",
                        }}
                        onChange={(e) => {
                          setWebsiteUrl(e.target.value);
                        }}
                        value={websiteUrl} // Set the value of the textarea to the entered website URL
                      />
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <button
                          className="clock_button"
                          onClick={handleUrlFieldSave}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        paddingLeft: 16,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                      onClick={handleUrlFieldOpen}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <img src={Website} alt="Website" />
                        <p style={{ paddingLeft: 15 }}>
                          {!websiteUrl
                            ? "Website (Optional)"
                            : "Website URL Entered"}
                        </p>
                      </div>
                      <img
                        style={{ paddingRight: 20 }}
                        src={Addicon}
                        alt="Addicon"
                      />
                    </div>
                  )}
                  {websiteUrl && (
                    <div style={{ marginLeft: "5%" }}>
                      <p>Entered Website URL:</p>
                      <p>{websiteUrl}</p>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  alignSelf: "center",
                  paddingTop: 50,
                  marginBottom: 10,
                  marginLeft: 60,
                }}
              >
                {props.event ? (
                  <button
                    className="Create_event_button"
                    onClick={(e) => {
                      upDateData(e);
                      // onPressImageData(e);
                    }}
                  >
                    Update Event
                  </button>
                ) : (
                  <button
                    className="Create_event_button"
                    onClick={(e) => {
                      onPressaddData(e);
                      // onPressImageData(e);
                    }}
                  >
                    Create Event
                  </button>
                )}
              </div>
            </div>
          )}
        </Modal>
      </div>
    </>
  );
}
export default ProfileSlider;
