import { atom } from 'recoil'

export const arrayEmerge = atom({
  key: 'sliderarraydata',
  default: [],
})
export const user_id = atom({
  key: 'user_id',
  default: null,
})
export const id_for_event = atom({
  key: 'id_for_event',
  default: null,
})
export const eventFormatdata = atom({
  key: 'eventFormatdata',
  default: [],
})
export const eventType = atom({
  key: 'eventType',
  default: null,
})
export const typeOf = atom({
  key: 'typeOf',
  default: null,
})
export const Token = atom({
  key: 'Token',
  default: null,
})
