import React, { useEffect, useState } from "react";
import profile from '../../Assetes/Profile.png'
import Calendar from '../../Assetes/calendar.svg'
import clock from '../../Assetes/clock.svg'
import Documentsvg from '../../Assetes/Document.svg'
import Website from '../../Assetes/Website.svg'
import Playstyle from '../../Assetes/Playstyle.svg'
import Addicon from "../../Assetes/Add.svg"
import Toggle from 'react-styled-toggle';
import backIcon from '../../Assetes/icon.svg'

import glofer from '../../Assetes/Golfer.png'
import share from '../../Assetes/Share.svg'
import locationIcon from '../../Assetes/svg/location.svg'
import { Modal } from "@mui/material";
import config from '../../Services/config'
import { useRecoilState, useRecoilValue } from "recoil";
import { user_id } from "../../Services/state";
// let tags = ['Chip Label', 'Tag 2', 'Chip Label', 'Chip Label', 'Tag 5']
function EventSlider(props) {
    const [data, setData] = useState([]);
    const userId = useRecoilValue(user_id)
    useEffect(() => {
        getEventList()
    }, [])
    // console.log(userId,"userId")
    const getEventList = () => {
        fetch(`${config.backend_url}/eventUser/${userId}`)
            .then(response => response.json())
            .then(data => {
                // Use the data returned from the API
                setData(data)
            })
            .catch(error => {
                // Handle any errors that occur
                console.error(error);
            });
    }
    const mergedData = [data[0]?.eventType, data[0]?.eventFormat, data[0]?.openTo]
    // mergedData.map((tag, index) => console.log(tag, index));
    return (
        <Modal open={props.handleEvent1} onClose={!props.handleEvent1} style={{ overflow: 'auto', display: 'flex', flexDirection: 'row-reverse'}}>
            <div style={{ display: 'flex', backgroundColor: 'white', width: "30%", flexDirection: "column" }} className="border">
                <div className='silder_start'>
                    <img style={{paddingTop:5}} src={backIcon} onClick={props.handleEvent1} />
                    <button className='edit'>Edit</button>
                    <button className='Share'>Share</button>
                </div>
                {data[0]?.image
                    ? <img style={{ width: "100%", height: 200 }} alt='' src={data[0].image} />
                    : <p>Image is not correct</p>
                }
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <h1 style={{ paddingLeft: 20 }}>{data[0]?.title}</h1>
                        <img style={{ paddingRight: 20 }} src={share} />
                    </div>
                    <div style={{ paddingLeft: 16, display: "flex", flexDirection: 'row', }}>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                            <img src={Calendar} />
                            <div>
                                <p style={{ paddingLeft: 15, margin: 0, fontWeight: 600, fontSize: 18, color: '#03294F' }}>Date</p>
                                <p style={{ paddingLeft: 15, margin: 0 }}>{data[0]?.date}</p>
                            </div>
                        </div>

                    </div>
                    <div style={{ paddingLeft: 16, display: "flex", flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                            <img src={clock} />
                            <div>
                                <p style={{ paddingLeft: 15, margin: 0, fontWeight: 600, fontSize: 18, color: '#03294F' }}>Start Time</p>
                                <p style={{ paddingLeft: 15, margin: 0 }}>{data[0]?.startTime}</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingLeft: 16, display: "flex", flexDirection: 'row', }}>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                            <img src={locationIcon} />
                            <div>
                                <p style={{ paddingLeft: 15, margin: 0, fontWeight: 600, fontSize: 18, color: '#03294F' }}>Location</p>
                                <p style={{ paddingLeft: 15, margin: 0, fontSize: 16 }}>Butter Valley</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingLeft: 16, display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ overflow:"scroll", display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                            <img src={Playstyle} />

                            <div>
                                <p style={{ paddingLeft: 15, margin: 0, fontWeight: 600, fontSize: 18, color: '#03294F' }}>Play Style (Optional)</p>
                                {mergedData.map((tag, index) => (
                                    <div style={{ display: 'flex' }} >
                                        {tag?.map((tags, subIndex) => (
                                                <div>
                                            {subIndex%1=== 0 ? (
                                                <div className='tags'>
                                                    <p className='tags-text'>{tags}</p>
                                                </div>
                                            ):null
                                        }</div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingLeft: 16, display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                            <img src={Documentsvg} />
                            <div>
                                <p style={{ paddingLeft: 15, margin: 0, fontWeight: 600, fontSize: 18, color: '#03294F' }}>Description</p>
                                <p style={{ paddingLeft: 15, margin: 0, fontSize: 16 }}>{data[0]?.description}</p>
                            </div>
                        </div>

                    </div>
                    <div style={{ paddingLeft: 16, display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                            <img src={Website} />
                            <div>
                                <p style={{ paddingLeft: 15, margin: 0, fontWeight: 600, fontSize: 18, color: '#03294F' }}>Website (Optional)</p>
                                <a style={{ paddingLeft: 15, margin: 0, fontSize: 16 }} href='www.websitelink.com'>{data[0]?.website}</a>
                            </div>                            </div>
                    </div>
                </div>



            </div>
        </Modal>
    )
}
export default EventSlider;